import { $iq, Strophe } from "strophe.js";
import("strophejs-plugin-disco");
import("strophejs-plugin-caps");

Strophe.addConnectionPlugin("disco", {
  _connection: null,
  _identities: [],
  _features: [],
  _items: [],
  init: function(conn) {
    this._connection = conn;
    this._identities = [];
    this._features = [];
    this._items = [];
    conn.addHandler(
      this._onDiscoInfo.bind(this),
      Strophe.NS.DISCO_INFO,
      "iq",
      "get",
      null,
      null
    );
    conn.addHandler(
      this._onDiscoItems.bind(this),
      Strophe.NS.DISCO_ITEMS,
      "iq",
      "get",
      null,
      null
    );
  },
  addIdentity: function(category, type, name, lang) {
    for (var i = 0; i < this._identities.length; i++) {
      if (
        this._identities[i].category == category &&
        this._identities[i].type == type &&
        this._identities[i].name == name &&
        this._identities[i].lang == lang
      ) {
        return false;
      }
    }
    this._identities.push({
      category: category,
      type: type,
      name: name,
      lang: lang,
    });
    return true;
  },
  addFeature: function(var_name) {
    for (var i = 0; i < this._features.length; i++) {
      if (this._features[i] == var_name) {
        return false;
      }
    }
    this._features.push(var_name);
    return true;
  },
  removeFeature: function(var_name) {
    for (var i = 0; i < this._features.length; i++) {
      if (this._features[i] === var_name) {
        this._features.splice(i, 1);
        return true;
      }
    }
    return false;
  },
  addItem: function(jid, name, node, call_back) {
    if (node && !call_back) {
      return false;
    }
    this._items.push({
      jid: jid,
      name: name,
      node: node,
      call_back: call_back,
    });
    return true;
  },
  info: function(jid, node, success, error, timeout) {
    var attrs = { xmlns: Strophe.NS.DISCO_INFO };
    if (node) {
      attrs.node = node;
    }
    var info = $iq({ from: this._connection.jid, to: jid, type: "get" }).c(
      "query",
      attrs
    );
    this._connection.sendIQ(info, success, error, timeout);
  },
  items: function(jid, node, success, error, timeout) {
    var attrs = { xmlns: Strophe.NS.DISCO_ITEMS };
    if (node) {
      attrs.node = node;
    }
    var items = $iq({ from: this._connection.jid, to: jid, type: "get" }).c(
      "query",
      attrs
    );
    this._connection.sendIQ(items, success, error, timeout);
  },
  _buildIQResult: function(stanza, query_attrs) {
    var id = stanza.getAttribute("id");
    var from = stanza.getAttribute("from");
    var iqresult = $iq({ type: "result", id: id });
    if (from !== null) {
      iqresult.attrs({ to: from });
    }
    return iqresult.c("query", query_attrs);
  },
  _onDiscoInfo: function(stanza) {
    var node = stanza.getElementsByTagName("query")[0].getAttribute("node");
    var attrs = { xmlns: Strophe.NS.DISCO_INFO };
    if (node) {
      attrs.node = node;
    }
    var iqresult = this._buildIQResult(stanza, attrs);
    for (var i = 0; i < this._identities.length; i++) {
      attrs = {
        category: this._identities[i].category,
        type: this._identities[i].type,
      };
      if (this._identities[i].name) {
        attrs.name = this._identities[i].name;
      }
      if (this._identities[i].lang) {
        attrs["xml:lang"] = this._identities[i].lang;
      }
      iqresult.c("identity", attrs).up();
    }
    for (var x = 0; x < this._features.length; x++) {
      iqresult.c("feature", { var: this._features[x] }).up();
    }
    this._connection.send(iqresult.tree());
    return true;
  },
  _onDiscoItems: function(stanza) {
    var query_attrs = { xmlns: Strophe.NS.DISCO_ITEMS };
    var node = stanza.getElementsByTagName("query")[0].getAttribute("node");
    var items = [];
    if (node) {
      query_attrs.node = node;
      for (var i = 0; i < this._items.length; i++) {
        if (this._items[i].node == node) {
          items = this._items[i].call_back(stanza);
          break;
        }
      }
    } else {
      items = this._items;
    }
    var iqresult = this._buildIQResult(stanza, query_attrs);
    for (var x = 0; x < items.length; x++) {
      var attrs = { jid: items[x].jid };
      if (items[x].name) {
        attrs.name = items[x].name;
      }
      if (items[x].node) {
        attrs.node = items[x].node;
      }
      iqresult.c("item", attrs).up();
    }
    this._connection.send(iqresult.tree());
    return true;
  },
});

// console.log('Strophe: ',Strophe)